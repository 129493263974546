import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import * as css from "./Button.module.css";

export const Button = ({
  className,
  href,
  variant,
  size,
  onClick,
  children,
  disabled
}) => {
  const classes = classnames(css.root, {
    [className]: className,
    [css[variant]]: css[variant],
    [css[size]]: css[size]
  });

  if (href) {
    if (href.startsWith("http")) {
      return (
        <a href={href} className={classes}>
          {children}
        </a>
      );
    } else {
      return (
        <Link to={href} className={classes}>
          {children}
        </Link>
      );
    }
  } else {
    return (
      <button className={classes} onClick={onClick} disabled={disabled}>
        {children}
      </button>
    );
  }
};

export default Button;
