import React from "react";
import { Helmet } from "react-helmet";

import "../style/index.css";
import "../style/fonts.css";
import "../style/highlight.css";

const Layout = ({ title, className, children }) => {
  return (
    <div className={className}>
      <Helmet>
        <title>{title}</title>

        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="icon" type="image/png" href="/favicon.png" sizes="32x32" />
        <link
          rel="icon"
          type="image/png"
          href="/favicon@2x.png"
          sizes="64x64"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@designsystemsin" />
        <meta name="twitter:creator" content="@designsystemsin" />
        <meta property="og:url" content="https://mechanic.design" />
        <meta property="og:title" content={title} />
        <meta
          property="og:description"
          content="An open source framework that makes it easy to create custom, web-based design tools that export design assets right in your browser."
        />
        <meta
          property="og:image"
          content="https://mechanic.design/mechanic.png"
        />
      </Helmet>

      {children}
    </div>
  );
};

export default Layout;
